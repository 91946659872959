import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import ProjectLoadModalComponent, { PHASES } from '../components/project-load-modal/project-load-modal.jsx';
import VM from 'scratch3-vm-scratchlink';
import extensionData from '../lib/libraries/extensions/index.jsx';
import {connect} from 'react-redux';
import {closeProjectLoadModal, setProjectLoadModalPhase} from '../reducers/modals';
import {requestNewProject} from '../reducers/project-state'

import {
    setProjectTitle
} from '../reducers/project-title';

class ProjectLoadModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClickGamepadType',
            'handleClickCodingType',
            'handleClickHardwareType',
            'handleClickChallengeType',
            'handleClickExampleType',
            'handleClickProjectType',
            'handleClickBlockLevel',
            'handleOnBack',
        ]);

        this.state = {
            extension: props.vm.runtime.peripheralExtensions.ScratchLink._extension,
            hardwareImages: {
            },
            codingTypeSelected: null,
            projectTypeSelected: null,
            hardwareSelected: null,
            challengeSelected: null
        }
    }

    handleOnBack() {
        if(this.props.projectLoadModalPhase === PHASES.blockLevelSelecting) {
            if(this.state.challengeSelected === 'Empty Project' || this.state.projectTypeSelected !== this.props.vm.ProjectTypes.CUSTOM) {
                this.props.setProjectLoadModalPhase(PHASES.challengeSelecting);
                return this.setState({
                    projectTypeSelected: null,
                    challengeSelected: null
                });
            }
            this.props.setProjectLoadModalPhase(PHASES.projectTypeSelecting);
            return this.setState({
                projectTypeSelected: null,
            });            
        }
        if(this.props.projectLoadModalPhase === PHASES.projectTypeSelecting) {
            this.props.setProjectLoadModalPhase(PHASES.challengeSelecting);
            return this.setState({
                projectTypeSelected: null,
                challengeSelected: null
            });
        }
        if(this.props.projectLoadModalPhase === PHASES.challengeSelecting) {
            if(this.state.codingTypeSelected === 'Scratch Game') {
                this.props.setProjectLoadModalPhase(PHASES.codingTypeSelecting)
                return this.setState({
                    hardwareSelected: null,
                    challengeSelected: null
                })
            }
            
            this.props.setProjectLoadModalPhase(PHASES.hardwareSelecting)
            return this.setState({
                projectTypeSelected: null,
                hardwareSelected: null,
                challengeSelected: null
            })
        }
        if(this.props.projectLoadModalPhase === PHASES.hardwareSelecting) {
            this.props.setProjectLoadModalPhase(PHASES.codingTypeSelecting);
            return this.setState({
                projectTypeSelected: null,
                hardwareSelected: null,
                challengeSelected: null,
                gamepadSelected: null
            })
        }
        if(this.props.projectLoadModalPhase === PHASES.codingTypeSelecting) {
            this.props.setProjectLoadModalPhase(PHASES.gamepadSelecting);
            return this.setState({
                projectTypeSelected: null,
                codingTypeSelected: null,
                hardwareSelected: null,
                challengeSelected: null,
                gamepadSelected: null
            });
        }
    }

    handleClickGamepadType(gamepadType) {
        this.setState({
            gamepadSelected: gamepadType
        })
        this.props.setProjectLoadModalPhase(PHASES.codingTypeSelecting)
    }

    handleClickCodingType(codingType) {
        if(codingType === 'Scratch Game') {
            this.setState({
                hardwareSelected: this.props.vm.HardwareTypes.no_hardware,
                codingTypeSelected: codingType
            })

            this.props.setProjectLoadModalPhase(PHASES.challengeSelecting)
            return
        }
        
    
        this.setState({
            codingTypeSelected: codingType
        })
        this.props.setProjectLoadModalPhase(PHASES.hardwareSelecting)
    }

    handleClickHardwareType(hardwareType) {
        this.setState({
            hardwareSelected: hardwareType
        })
        this.props.setProjectLoadModalPhase(PHASES.challengeSelecting)
    }

    handleClickProjectType(projectType) {
        this.props.setProjectLoadModalPhase(PHASES.blockLevelSelecting);
        this.setState({
            projectTypeSelected: projectType
        });
    }

    handleClickChallengeType(challengeType) {
        if(challengeType === 'Empty Project') {
            this.props.setProjectLoadModalPhase(PHASES.blockLevelSelecting);
            return this.setState({
                challengeSelected: challengeType,
                projectTypeSelected: this.props.vm.ProjectTypes.CUSTOM
            });
        }

        this.props.setProjectLoadModalPhase(PHASES.projectTypeSelecting);
        this.setState({
            challengeSelected: challengeType
        });
    }

    handleClickExampleType(exampleType) {
        this.props.setProjectLoadModalPhase(PHASES.blockLevelSelecting);
        this.setState({
            challengeSelected: exampleType,
            projectTypeSelected: this.props.vm.ProjectTypes.EXAMPLE
        });
    }

    handleClickBlockLevel(blockLevel) {
        if(this.state.projectTypeSelected === this.props.vm.ProjectTypes.DEMO) {
            const newProjectLink = `${window.location.origin}/#/newProject/${this.state.gamepadSelected}/${this.state.hardwareSelected}/${this.state.challengeSelected}/${this.state.projectTypeSelected}/${blockLevel}?openDemoMode=true`;

            window.open(newProjectLink, '_blank').focus();
        } else {
            this.props.setProjectTitle('Untitled Project')

            this.props.vm.loadExampleScratchLinkProject('No Gamepad', this.state.hardwareSelected,  this.state.challengeSelected, this.state.projectTypeSelected, blockLevel);
        }
       
        this.props.setProjectLoadModalPhase(PHASES.hardwareSelecting);
        setTimeout(() => {
            this.setState({
                //gamepadSelected: null,
                hardwareSelected: null,
                challengeSelected: null,
                projectTypeSelected: null
            }); 
        }, 100);
        this.props.onCancel();
    }

    render () {
        let title = 'New Project | Choose Coding Type';
        if(this.props.projectLoadModalPhase === PHASES.hardwareSelecting) title = 'Choose ScratchLink Hardware Type'
        if(this.props.projectLoadModalPhase === PHASES.challengeSelecting) title = `Choose Challenge`
        if(this.props.projectLoadModalPhase === PHASES.projectTypeSelecting) title = `Choose Project Type`
        if(this.props.projectLoadModalPhase === PHASES.blockLevelSelecting) title = `Choose Level`

        if(this.props.projectLoadModalPhase === PHASES.hardwareSelecting && this.state.codingTypeSelected === null) { //If simualtor shortcut is clicked
            this.setState({
                codingTypeSelected: 'Robot Simulator'
            })
        }

        return (
            <ProjectLoadModalComponent
                name={title}
                phase={this.props.projectLoadModalPhase}
                extension={this.state.extension}
                smallPeripheralImage={extensionData.find(ext => ext.extensionId === 'ScratchLink').smallPeripheralImage}
                vm={this.props.vm}
                codingTypeSelected={this.state.codingTypeSelected}
                hardwareSelected={this.state.hardwareSelected}
                challengeSelected={this.state.challengeSelected}
                projectTypeSelected={this.state.projectTypeSelected}
                currentProfile={this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.currentProfile}
                hardwareImages={this.state.hardwareImages}
                handleClickGamepadType={this.handleClickGamepadType}
                handleClickCodingType={this.handleClickCodingType}
                handleClickHardwareType={this.handleClickHardwareType}
                handleClickExampleType={this.handleClickExampleType}
                handleClickChallengeType={this.handleClickChallengeType}
                handleClickProjectType={this.handleClickProjectType}
                handleClickBlockLevel={this.handleClickBlockLevel}
                onCancel={this.props.onCancel}
                onBack={this.handleOnBack}
            />
        );
    }
}

ProjectLoadModal.propTypes = {
    vm: PropTypes.instanceOf(VM).isRequired,
    projectLoadModalPhase: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onEmptyProject: PropTypes.func.isRequired,
    setProjectTitle: PropTypes.func.isRequired,
    setProjectLoadModalPhase: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    projectLoadModalPhase: state.scratchGui.modals.projectLoadModalPhase
});

const mapDispatchToProps = dispatch => ({
    onCancel: () => {
        dispatch(closeProjectLoadModal());
    },
    onEmptyProject: () => {
        dispatch(requestNewProject());
    },
    setProjectTitle: (title) => dispatch(setProjectTitle(title)),
    setProjectLoadModalPhase: (phase) => dispatch(setProjectLoadModalPhase(phase))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectLoadModal);