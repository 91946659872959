import classNames from 'classnames';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import {ComingSoonTooltip} from '../coming-soon/coming-soon.jsx';
import Divider from '../divider/divider.jsx';
import SBFileUploader from '../../containers/sb-file-uploader.jsx';
import MenuBarMenu from './menu-bar-menu.jsx';
import {MenuItem, MenuSection} from '../menu/menu.jsx';
import GamePadConnection from './gamepad-connection.jsx';

import VM from 'scratch3-vm-scratchlink';

import {openTipsLibrary, openConnectionModal as openConnectionModalAction} from '../../reducers/modals';
import {setPlayer} from '../../reducers/mode';
import {
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy,
} from '../../reducers/project-state';
import {
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen
} from '../../reducers/menus';

import {
    openProjectLoadModal,
    openFileModal
} from '../../reducers/modals';

import styles from './menu-bar.css';

import helpIcon from '../../lib/assets/icon--tutorials.svg';
import mystuffIcon from './icon--mystuff.png';
import feedbackIcon from './icon--feedback.svg';
import profileIcon from './icon--profile.png';
import remixIcon from './icon--remix.svg';
import dropdownCaret from './dropdown-caret.svg';
import languageIcon from '../language-selector/language-icon.svg';
import batteryIndicatorGreen from './battery-indicator-green.svg';
import batteryIndicatorRed from './battery-indicator-red.svg';

import wifiIcon from './icon--wifi.svg';
import wifiConnectedIcon from './icon--wifi--connected.svg';
import usbIcon from './icon--usb.svg';
import usbConnectedIcon from './icon--usb--connected.svg';

import scratchLogo from './scratch-link-logo.svg';
import ScratchLinkModes from './scratch-link-modes.jsx';
import statusReadySvg from './status-ready.svg';
import statusNotReadySvg from './status-not-ready.svg';

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = 'bottom'
}) => {
    if (enable) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};


MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

const MenuItemTooltip = ({id, isRtl, children, className}) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? 'left' : 'right'}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool
};

class MenuBar extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClickNew',
            'handleClickProject',
            'handleClickScratchLink',
            'handleClickOpenRemoteFile',
            'handleClickSaveRemoteFile',
            'handleClickSaveFile',
            'handleClickRemix',
            'handleClickSave',
            'handleClickSaveAsCopy',
            'handleClickSeeCommunity',
            'handleClickShare',
            'handleCloseFileMenuAndThen',
            'handleLanguageMouseUp',
            'handleRestoreOption',
            'restoreOptionMessage',
            'handlePeripheralConnectionChange',
            'handleScratchLinkProfileUpdate',
            'handleStartScratchLinkSimulator',
            'handleBlockLevelUpgrade',
            'handleBatteryError',
            'onProjectChanged'
        ]);

        this.state = {
            connected: this.props.vm.getPeripheralIsConnected('ScratchLink'),
            connectionName: 'Connection',
            batteryError: false
        }

        this.exampleProjectNames = Object.keys(props.vm.ExampleProjects);
    }
    componentDidMount () {
        this.props.vm.on('PERIPHERAL_CONNECTED', this.handlePeripheralConnectionChange);
        this.props.vm.on('PERIPHERAL_REQUEST_ERROR', this.handlePeripheralConnectionChange);
        this.props.vm.on('PERIPHERAL_DISCONNECTED', this.handlePeripheralConnectionChange);
        this.props.vm.on('SCRATCHLINK_DEVICE_PROFILE_UPDATE', this.handleScratchLinkProfileUpdate);
        this.props.vm.on('SCRATCHLINK_DEVICE_BATTERY_ERROR', this.handleBatteryError);
        this.props.vm.addListener('PROJECT_CHANGED', this.onProjectChanged);
    }
    componentWillUnmount () {
        this.props.vm.removeListener('PERIPHERAL_CONNECTED', this.handlePeripheralConnectionChange);
        this.props.vm.removeListener('PERIPHERAL_REQUEST_ERROR', this.handlePeripheralConnectionChange);
        this.props.vm.removeListener('PERIPHERAL_DISCONNECTED', this.handlePeripheralConnectionChange);
        this.props.vm.removeListener('SCRATCHLINK_DEVICE_PROFILE_UPDATE', this.handleScratchLinkProfileUpdate);
        this.props.vm.removeListener('SCRATCHLINK_DEVICE_BATTERY_ERROR', this.handleBatteryError);
        this.props.vm.removeListener('PROJECT_CHANGED', this.onProjectChanged);
    }
    onProjectChanged() {
        this.forceUpdate();
    }
    handlePeripheralConnectionChange() {
        this.setState({
            connected: this.props.vm.getPeripheralIsConnected('ScratchLink')
        });
    }
    handleScratchLinkProfileUpdate(profile) {
        this.setState({
            connectionName: profile === '' ? 'Connection' : profile
        })
    }
    handleClickProject() {
        if('ontouchstart' in window || navigator.maxTouchPoints) return; //mobile

        this.props.openProjectLoadModal();
    }
    handleClickNew () {
        this.props.openProjectLoadModal();
    }
    handleClickScratchLink() {
        this.props.openConnectionModal();
    }
    handleClickOpenRemoteFile() {
        this.props.vm.fileModalPhase = 'open';
        this.props.openFileModal();
    }
    handleClickSaveRemoteFile() {
        this.props.vm.fileModalPhase = 'saveRemote';
        this.props.openFileModal();
    }
    handleClickSaveFile() {
        const extension = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension;

        if((extension.projectType === this.props.vm.ProjectTypes.EXAMPLE || extension.projectType === this.props.vm.ProjectTypes.DEMO) && window.location.hostname !== '127.0.0.1') {
            this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.emitAlert(`You cannot save example projects or demos.`);
            return; //Do not save example projects or demos
        }

        this.props.vm.fileModalPhase = 'save';
        this.props.openFileModal();
    }
    handleClickRemix () {
        this.props.onClickRemix();
        this.props.onRequestCloseFile();
    }
    handleClickSave () {
        this.props.onClickSave();
        this.props.onRequestCloseFile();
    }
    handleClickSaveAsCopy () {
        this.props.onClickSaveAsCopy();
        this.props.onRequestCloseFile();
    }
    handleClickSeeCommunity (waitForUpdate) {
        if (this.props.canSave) { // save before transitioning to project page
            this.props.autoUpdateProject();
            waitForUpdate(true); // queue the transition to project page
        } else {
            waitForUpdate(false); // immediately transition to project page
        }
    }
    handleClickShare (waitForUpdate) {
        if (!this.props.isShared) {
            if (this.props.canShare) { // save before transitioning to project page
                this.props.onShare();
            }
            if (this.props.canSave) { // save before transitioning to project page
                this.props.autoUpdateProject();
                waitForUpdate(true); // queue the transition to project page
            } else {
                waitForUpdate(false); // immediately transition to project page
            }
        }
    }
    handleRestoreOption (restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleCloseFileMenuAndThen (fn) {
        return () => {
            this.props.onRequestCloseFile();
            fn();
        };
    }
    handleLanguageMouseUp (e) {
        if (!this.props.languageMenuOpen) {
            this.props.onClickLanguage(e);
        }
    }
    handleStartScratchLinkSimulator(projectName) {
        this.props.vm.loadExampleScratchLinkProject(projectName);
    }
    handleBlockLevelUpgrade(nextBlockLevel) {
        if(window.confirm(`Are you sure you want to upgrade to ${nextBlockLevel} blocks?`)) this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.changeBlockLevelMode(nextBlockLevel, true);
    }
    handleBatteryError() {
        this.setState({batteryError: true});
    }
    restoreOptionMessage (deletedItem) {
        switch (deletedItem) {
        case 'Sprite':
            return (<FormattedMessage
                defaultMessage="Restore Sprite"
                description="Menu bar item for restoring the last deleted sprite."
                id="gui.menuBar.restoreSprite"
            />);
        case 'Sound':
            return (<FormattedMessage
                defaultMessage="Restore Sound"
                description="Menu bar item for restoring the last deleted sound."
                id="gui.menuBar.restoreSound"
            />);
        case 'Costume':
            return (<FormattedMessage
                defaultMessage="Restore Costume"
                description="Menu bar item for restoring the last deleted costume."
                id="gui.menuBar.restoreCostume"
            />);
        default: {
            return (<FormattedMessage
                defaultMessage="Restore"
                description="Menu bar item for restoring the last deleted item in its disabled state." /* eslint-disable-line max-len */
                id="gui.menuBar.restore"
            />);
        }
        }
    }
    render () {
        const saveNowMessage = (
            <FormattedMessage
                defaultMessage="Save now"
                description="Menu bar item for saving now"
                id="gui.menuBar.saveNow"
            />
        );
        const createCopyMessage = (
            <FormattedMessage
                defaultMessage="Save as a copy"
                description="Menu bar item for saving as a copy"
                id="gui.menuBar.saveAsCopy"
            />
        );
        const remixMessage = (
            <FormattedMessage
                defaultMessage="Remix"
                description="Menu bar item for remixing"
                id="gui.menuBar.remix"
            />
        );
        const newProjectMessage = (
            <FormattedMessage
                defaultMessage="New Project"
                description="Menu bar item for creating a new project"
                id="gui.menuBar.newProject"
            />
        );
        let nextBlockLevel = '';
        let upgradeBlockLevelMsg = '';
        if(this.props.scratchLinkExtensionLoaded) {
            const blockLevelMode = this.props.vm.runtime.peripheralExtensions.ScratchLink._extension.blockLevelMode;
            if(blockLevelMode === 'Beginner') nextBlockLevel = 'Core';
            if(blockLevelMode === 'Core') nextBlockLevel = 'Advanced';

            if(nextBlockLevel !== '') upgradeBlockLevelMsg = `Upgrade to ${nextBlockLevel} blocks`;
        }

        const shouldShowWifiNotConnected = !this.state.connected && window.location.protocol === 'http:';
        const shouldShowUsbNotConnected = !this.state.connected && window.location.protocol === 'https:';
        const shouldShowWifiConnected = this.state.connected && window.location.protocol === 'http:';
        const shouldShowUsbConnected = this.state.connected && window.location.protocol === 'https:';
        
        return (
            <Box
                className={classNames(
                    this.props.className,
                    styles.menuBar
                )}
            >
                <div className={styles.mainMenu}>
                    <div className={styles.fileGroup}>
                        <div className={classNames(styles.menuBarItem)}>
                            <img
                                alt="Scratch"
                                className={classNames(styles.scratchLogo, {
                                    [styles.clickable]: typeof this.props.onClickLogo !== 'undefined'
                                })}
                                draggable={false}
                                src={scratchLogo}
                                onClick={this.props.onClickLogo}
                            />
                        </div>
                        <div className={classNames(styles.menuBarItem, styles.selectProjectDropdown)}>
                            <div className={classNames(styles.menuBarItem, styles.hoverable)} style={{background: '#4C97FF'}} onClick={this.handleClickProject}>Project <img style={{marginLeft: '5px'}} src={dropdownCaret} alt="Dropdown Caret" /></div>
                            <div className={styles.selectProjectDropdownBody}>
                                <MenuSection>
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.handleClickNew}
                                    >
                                        {newProjectMessage}
                                    </MenuItem>
                                </MenuSection>
                                {(this.props.canSave || this.props.canCreateCopy || this.props.canRemix) && (
                                    <MenuSection>
                                        {this.props.canSave ? (
                                            <MenuItem onClick={this.handleClickSave}>
                                                {saveNowMessage}
                                            </MenuItem>
                                        ) : []}
                                        {this.props.canCreateCopy ? (
                                            <MenuItem onClick={this.handleClickSaveAsCopy}>
                                                {createCopyMessage}
                                            </MenuItem>
                                        ) : []}
                                        {this.props.canRemix ? (
                                            <MenuItem onClick={this.handleClickRemix}>
                                                {remixMessage}
                                            </MenuItem>
                                        ) : []}
                                    </MenuSection>
                                )}
                                <MenuSection>
                                    <SBFileUploader onUpdateProjectTitle={this.props.onUpdateProjectTitle}>
                                        {(className, renderFileInput, loadProject) => (
                                            <MenuItem
                                                className={className}
                                                onClick={loadProject}
                                            >
                                                Load Project
                                                {renderFileInput()}
                                            </MenuItem>
                                        )}
                                    </SBFileUploader>
                                    {/* <MenuItem
                                        onClick={this.handleClickOpenRemoteFile}
                                    >
                                        Load Remote Project
                                    </MenuItem> */}
                                    <MenuItem
                                        onClick={() => this.handleClickSaveFile()}
                                    >
                                        Save Project
                                    </MenuItem>
                                    {/* <MenuItem
                                        onClick={this.handleClickSaveRemoteFile}
                                    >
                                        Save Remote Project
                                    </MenuItem> */}
                                    <MenuItem
                                        onClick={() => this.handleBlockLevelUpgrade(nextBlockLevel)}
                                    >
                                        { upgradeBlockLevelMsg }
                                    </MenuItem>
                                </MenuSection>
                            </div>
                        </div>
                        {/* <div
                            className={classNames(styles.menuBarItem, styles.hoverable, {
                                [styles.active]: this.props.fileMenuOpen
                            })}
                            onMouseUp={this.props.onClickFile}
                        >
                            <FormattedMessage
                                defaultMessage="File"
                                description="Text for file dropdown menu"
                                id="gui.menuBar.file"
                            />
                            <MenuBarMenu
                                className={classNames(styles.menuBarMenu)}
                                open={this.props.fileMenuOpen}
                                place={this.props.isRtl ? 'left' : 'right'}
                                onRequestClose={this.props.onRequestCloseFile}
                            >
                                <MenuSection>
                                    <MenuItem
                                        isRtl={this.props.isRtl}
                                        onClick={this.handleClickNew}
                                    >
                                        {newProjectMessage}
                                    </MenuItem>
                                </MenuSection>
                                {(this.props.canSave || this.props.canCreateCopy || this.props.canRemix) && (
                                    <MenuSection>
                                        {this.props.canSave ? (
                                            <MenuItem onClick={this.handleClickSave}>
                                                {saveNowMessage}
                                            </MenuItem>
                                        ) : []}
                                        {this.props.canCreateCopy ? (
                                            <MenuItem onClick={this.handleClickSaveAsCopy}>
                                                {createCopyMessage}
                                            </MenuItem>
                                        ) : []}
                                        {this.props.canRemix ? (
                                            <MenuItem onClick={this.handleClickRemix}>
                                                {remixMessage}
                                            </MenuItem>
                                        ) : []}
                                    </MenuSection>
                                )}
                                <MenuSection>
                                    <SBFileUploader onUpdateProjectTitle={this.props.onUpdateProjectTitle}>
                                        {(className, renderFileInput, loadProject) => (
                                            <MenuItem
                                                className={className}
                                                onClick={loadProject}
                                            >
                                                <FormattedMessage
                                                    defaultMessage="Load from your computer"
                                                    description={
                                                        'Menu bar item for uploading a project from your computer'
                                                    }
                                                    id="gui.menuBar.uploadFromComputer"
                                                />
                                                {renderFileInput()}
                                            </MenuItem>
                                        )}
                                    </SBFileUploader>
                                    <SB3Downloader>{(className, downloadProject) => (
                                        <MenuItem
                                            className={className}
                                            onClick={this.handleCloseFileMenuAndThen(downloadProject)}
                                        >
                                            <FormattedMessage
                                                defaultMessage="Save to your computer"
                                                description="Menu bar item for downloading a project to your computer"
                                                id="gui.menuBar.downloadToComputer"
                                            />
                                        </MenuItem>
                                    )}</SB3Downloader>
                                </MenuSection>
                                
                            </MenuBarMenu>
                        </div> */}
                        <div className={classNames(styles.menuBarItem, styles.hoverable)} onClick={this.handleClickScratchLink}>
                            <span 
                                data-for="connection-name" 
                                data-tip={this.state.connected ? `Connected to ScratchLink Device: ${this.state.connectionName}` : `Not Connected - click to connect to ScratchLink Device`}
                                >
                                {this.state.connectionName}
                            </span>
                            <span
                                data-for="connection-name" 
                                data-tip={this.state.connected ? `Connected to ScratchLink Device: ${this.state.connectionName}` : `Not Connected - click to connect to ScratchLink Device`}
                            >
                                {this.state.connected ?  
                                    <img
                                        alt="Connection Status"
                                        className={styles.connectionStatus}
                                        draggable={false}
                                        src={this.state.batteryError ? batteryIndicatorRed : batteryIndicatorGreen}
                                    /> :
                                    <img
                                        alt="Connection Status"
                                        className={styles.connectionStatus}
                                        draggable={false}
                                        src={statusNotReadySvg}
                                    />
                                }
                            </span>
                            <span
                                data-for="connection-connected"
                                data-tip={
                                    shouldShowWifiConnected || shouldShowWifiNotConnected ? "Connection Type: WiFi" : "Connection Type: USB Cable/Modem"
                                }
                            >
                                {
                                    shouldShowWifiConnected &&
                                        <img
                                            className={styles.connectionTypeIcon}
                                            draggable={false}
                                            src={wifiConnectedIcon}
                                        />                    
                                }
                                {
                                    shouldShowWifiNotConnected && 
                                        <img
                                            className={styles.connectionTypeIcon}
                                            draggable={false}
                                            src={wifiIcon} 
                                        />
                                }
                                {
                                    shouldShowUsbConnected &&
                                        <img
                                            className={styles.connectionTypeIcon}
                                            draggable={false}
                                            src={usbConnectedIcon}
                                        />
                                }
                                {
                                    shouldShowUsbNotConnected &&
                                        <img
                                            className={styles.connectionTypeIcon}
                                            draggable={false}
                                            src={usbIcon}
                                        />
                                }
                            </span>
                            <ReactTooltip
                                className={styles.connectionTooltip}
                                effect="solid"
                                id="connection-name"
                                place={'bottom'}
                            />
                            <ReactTooltip
                                className={styles.connectionTooltip}
                                effect="solid"
                                id="connection-connected"
                                place={'bottom'}
                            />
                        </div>
                        <div className={styles.menuBarItem}>
                            <GamePadConnection vm={this.props.vm} />
                        </div>
                        {/* <div
                            className={classNames(styles.menuBarItem, styles.hoverable, {
                                [styles.active]: this.props.editMenuOpen
                            })}
                            onMouseUp={this.props.onClickEdit}
                        >
                            <div className={classNames(styles.editMenu)}>
                                <FormattedMessage
                                    defaultMessage="Edit"
                                    description="Text for edit dropdown menu"
                                    id="gui.menuBar.edit"
                                />
                            </div>
                            <MenuBarMenu
                                className={classNames(styles.menuBarMenu)}
                                open={this.props.editMenuOpen}
                                place={this.props.isRtl ? 'left' : 'right'}
                                onRequestClose={this.props.onRequestCloseEdit}
                            >
                                <DeletionRestorer>{(handleRestore, {restorable, deletedItem}) => (
                                    <MenuItem
                                        className={classNames({[styles.disabled]: !restorable})}
                                        onClick={this.handleRestoreOption(handleRestore)}
                                    >
                                        {this.restoreOptionMessage(deletedItem)}
                                    </MenuItem>
                                )}</DeletionRestorer>
                                <MenuSection>
                                    <TurboMode>{(toggleTurboMode, {turboMode}) => (
                                        <MenuItem onClick={toggleTurboMode}>
                                            {turboMode ? (
                                                <FormattedMessage
                                                    defaultMessage="Turn off Turbo Mode"
                                                    description="Menu bar item for turning off turbo mode"
                                                    id="gui.menuBar.turboModeOff"
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    defaultMessage="Turn on Turbo Mode"
                                                    description="Menu bar item for turning on turbo mode"
                                                    id="gui.menuBar.turboModeOn"
                                                />
                                            )}
                                        </MenuItem>
                                    )}</TurboMode>
                                </MenuSection>
                                <MenuSection>
                                    <div style={{borderTop: '1px solid white'}} />
                                    <MenuItem clickable={false}>
                                        <FormattedMessage
                                                defaultMessage="Device IP"
                                                description=""
                                                id="gui.menuBar.deviceIp"
                                        />
                                    </MenuItem>
                                </MenuSection>
                            </MenuBarMenu>
                        </div> */}
                    </div>
                    <Divider className={classNames(styles.divider)} />
                </div>
                <div className={classNames(styles.mainMenu, styles.scratchLinkBlockModes)} >
                    <Divider className={classNames(styles.divider)} />
                    <ScratchLinkModes styles={styles} vm={this.props.vm} />
                </div>
                <div className={classNames(styles.mainMenu, styles.scratchLinkAdvertise)}>
                    <Divider className={classNames(styles.divider)} />
                    <a className={classNames(styles.menuBarItem, styles.hoverable)} href="https://scratchlinkcodes.au/" target="_blank">
                        Coding Editor
                    </a>
                    <Divider className={classNames(styles.divider)} />
                    <a className={classNames(styles.menuBarItem, styles.hoverable)} href="https://scratchlink.au/contact/" target="_blank">
                        Contact
                    </a>
                    <Divider className={classNames(styles.divider)} />
                    <a className={classNames(styles.menuBarItem, styles.hoverable)} href="https://scratchlink.au/resources/" target="_blank">
                        Resources
                    </a>
                </div>
            </Box>
        );
    }
}

/*
 <div
    className={classNames(styles.menuBarItem, styles.hoverable, styles.languageMenu)}
>
    <div>
        <img
            className={styles.languageIcon}
            src={languageIcon}
        />
        <img
            className={styles.languageCaret}
            src={dropdownCaret}
        />
    </div>
    <LanguageSelector label={this.props.intl.formatMessage(ariaMessages.language)} />
</div>
*/

MenuBar.propTypes = {
    accountMenuOpen: PropTypes.bool,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    editMenuOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isUpdating: PropTypes.bool,
    languageMenuOpen: PropTypes.bool,
    loginMenuOpen: PropTypes.bool,
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLanguage: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseLanguage: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    onUpdateProjectTitle: PropTypes.func,
    projectChanged: PropTypes.bool,
    projectTitle: PropTypes.string,
    renderLogin: PropTypes.func,
    sessionExists: PropTypes.bool,
    showComingSoon: PropTypes.bool,
    username: PropTypes.string,
    vm: PropTypes.instanceOf(VM).isRequired
};

MenuBar.defaultProps = {
    onShare: () => {}
};

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
        accountMenuOpen: accountMenuOpen(state),
        fileMenuOpen: fileMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        isRtl: state.locales.isRtl,
        isUpdating: getIsUpdating(loadingState),
        isShowingProject: getIsShowingProject(loadingState),
        languageMenuOpen: languageMenuOpen(state),
        loginMenuOpen: loginMenuOpen(state),
        projectChanged: state.scratchGui.projectChanged,
        projectTitle: state.scratchGui.projectTitle,
        sessionExists: state.session && typeof state.session.session !== 'undefined',
        username: user ? user.username : null,
        scratchLinkExtensionLoaded: state.scratchGui.scratchlink.extensionLoaded
    };
};

const mapDispatchToProps = dispatch => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onSeeCommunity: () => dispatch(setPlayer(true)),
    setProjectTitle: (title) => dispatch(setProjectTitle(title)),
    openConnectionModal: () => dispatch(openConnectionModalAction()),
    openProjectLoadModal: () => dispatch(openProjectLoadModal()),
    openFileModal: () => dispatch(openFileModal())
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuBar));
