import { PHASES } from '../components/project-load-modal/project-load-modal.jsx';

const OPEN_MODAL = 'scratch-gui/modals/OPEN_MODAL';
const CLOSE_MODAL = 'scratch-gui/modals/CLOSE_MODAL';

const MODAL_BACKDROP_LIBRARY = 'backdropLibrary';
const MODAL_CAMERA_CAPTURE = 'cameraCapture';
const MODAL_COSTUME_LIBRARY = 'costumeLibrary';
const MODAL_EXTENSION_LIBRARY = 'extensionLibrary';
const MODAL_IMPORT_INFO = 'importInfo';
const MODAL_LOADING_PROJECT = 'loadingProject';
const MODAL_PREVIEW_INFO = 'previewInfo';
const MODAL_TELEMETRY = 'telemetryModal';
const MODAL_SOUND_LIBRARY = 'soundLibrary';
const MODAL_SPRITE_LIBRARY = 'spriteLibrary';
const MODAL_SOUND_RECORDER = 'soundRecorder';
const MODAL_CONNECTION = 'connectionModal';
const MODAL_PROJECT_LOAD = 'projectLoadModal';
const MODAL_SCRATCHLINK_CONNECTION = 'scratchLinkConnectionModal';
const MODAL_FILE = 'fileModal';
const MODAL_TIPS_LIBRARY = 'tipsLibrary';
const SET_PROJECT_LOAD_MODAL_PHASE = 'setProjectLoadModalPhase';

const initialState = {
    [MODAL_BACKDROP_LIBRARY]: false,
    [MODAL_CAMERA_CAPTURE]: false,
    [MODAL_COSTUME_LIBRARY]: false,
    [MODAL_EXTENSION_LIBRARY]: false,
    [MODAL_IMPORT_INFO]: false,
    [MODAL_LOADING_PROJECT]: false,
    [MODAL_PREVIEW_INFO]: false,
    [MODAL_TELEMETRY]: false,
    [MODAL_SOUND_LIBRARY]: false,
    [MODAL_SPRITE_LIBRARY]: false,
    [MODAL_SOUND_RECORDER]: false,
    [MODAL_CONNECTION]: false,
    [MODAL_PROJECT_LOAD]: true,
    [MODAL_SCRATCHLINK_CONNECTION]: false,
    [MODAL_TIPS_LIBRARY]: false,
    [MODAL_FILE]: false,
    projectLoadModalPhase: PHASES.codingTypeSelecting
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case OPEN_MODAL:
        if(action.modal === MODAL_PROJECT_LOAD) {
            return Object.assign({}, state, {
                [action.modal]: true,
                projectLoadModalPhase:  PHASES.codingTypeSelecting
            });
        }
        return Object.assign({}, state, {
            [action.modal]: true
        });
    case CLOSE_MODAL:
        return Object.assign({}, state, {
            [action.modal]: false
        });
    case SET_PROJECT_LOAD_MODAL_PHASE:
        console.log('SET_PROJECT_LOAD_MODAL_PHASE', action.phase);
        return Object.assign({}, state, {
            projectLoadModalPhase: action.phase
        });
    default:
        return state;
    }
};
const openModal = function (modal) {
    return {
        type: OPEN_MODAL,
        modal: modal
    };
};
const closeModal = function (modal) {
    return {
        type: CLOSE_MODAL,
        modal: modal
    };
};
const openBackdropLibrary = function () {
    return openModal(MODAL_BACKDROP_LIBRARY);
};
const openCameraCapture = function () {
    return openModal(MODAL_CAMERA_CAPTURE);
};
const openCostumeLibrary = function () {
    return openModal(MODAL_COSTUME_LIBRARY);
};
const openExtensionLibrary = function () {
    return openModal(MODAL_EXTENSION_LIBRARY);
};
const openImportInfo = function () {
    return openModal(MODAL_IMPORT_INFO);
};
const openLoadingProject = function () {
    return openModal(MODAL_LOADING_PROJECT);
};
const openPreviewInfo = function () {
    return openModal(MODAL_PREVIEW_INFO);
};
const openTelemetryModal = function () {
    return openModal(MODAL_TELEMETRY);
};
const openSoundLibrary = function () {
    return openModal(MODAL_SOUND_LIBRARY);
};
const openSpriteLibrary = function () {
    return openModal(MODAL_SPRITE_LIBRARY);
};
const openSoundRecorder = function () {
    return openModal(MODAL_SOUND_RECORDER);
};
const openConnectionModal = function () {
    return openModal(MODAL_CONNECTION);
};
const openProjectLoadModal = function () {
    //projectLoadModalHasBeenOpen = true;
    return openModal(MODAL_PROJECT_LOAD);
};
const openScratchLinkConnectionModal = function () {
    return openModal(MODAL_SCRATCHLINK_CONNECTION);
};
const openFileModal = function () {
    return openModal(MODAL_FILE);
};
const openTipsLibrary = function () {
    return openModal(MODAL_TIPS_LIBRARY);
};
const closeBackdropLibrary = function () {
    return closeModal(MODAL_BACKDROP_LIBRARY);
};
const closeCameraCapture = function () {
    return closeModal(MODAL_CAMERA_CAPTURE);
};
const closeCostumeLibrary = function () {
    return closeModal(MODAL_COSTUME_LIBRARY);
};
const closeExtensionLibrary = function () {
    return closeModal(MODAL_EXTENSION_LIBRARY);
};
const closeImportInfo = function () {
    return closeModal(MODAL_IMPORT_INFO);
};
const closeLoadingProject = function () {
    return closeModal(MODAL_LOADING_PROJECT);
};
const closePreviewInfo = function () {
    return closeModal(MODAL_PREVIEW_INFO);
};
const closeTelemetryModal = function () {
    return closeModal(MODAL_TELEMETRY);
};
const closeSpriteLibrary = function () {
    return closeModal(MODAL_SPRITE_LIBRARY);
};
const closeSoundLibrary = function () {
    return closeModal(MODAL_SOUND_LIBRARY);
};
const closeSoundRecorder = function () {
    return closeModal(MODAL_SOUND_RECORDER);
};
const closeTipsLibrary = function () {
    return closeModal(MODAL_TIPS_LIBRARY);
};
const closeConnectionModal = function () {
    return closeModal(MODAL_CONNECTION);
};
const closeProjectLoadModal = function () {
    return closeModal(MODAL_PROJECT_LOAD);
};
const closeScratchLinkConnectionModal = function () {
    return closeModal(MODAL_SCRATCHLINK_CONNECTION);
};
const closeFileModal = function () {
    return closeModal(MODAL_FILE);
};
const setProjectLoadModalPhase = function (phase) {
    return {
        type: SET_PROJECT_LOAD_MODAL_PHASE,
        phase: phase
    };
};
export {
    reducer as default,
    initialState as modalsInitialState,
    openBackdropLibrary,
    openCameraCapture,
    openCostumeLibrary,
    openExtensionLibrary,
    openImportInfo,
    openLoadingProject,
    openPreviewInfo,
    openSoundLibrary,
    openSpriteLibrary,
    openSoundRecorder,
    openTelemetryModal,
    openTipsLibrary,
    openConnectionModal,
    openProjectLoadModal,
    openScratchLinkConnectionModal,
    openFileModal,
    closeBackdropLibrary,
    closeCameraCapture,
    closeCostumeLibrary,
    closeExtensionLibrary,
    closeImportInfo,
    closeLoadingProject,
    closePreviewInfo,
    closeSpriteLibrary,
    closeSoundLibrary,
    closeSoundRecorder,
    closeTelemetryModal,
    closeTipsLibrary,
    closeConnectionModal,
    closeProjectLoadModal,
    closeScratchLinkConnectionModal,
    closeFileModal,
    setProjectLoadModalPhase
};
